<template>
<!-- 我的问题列表 -->
  <div class="container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <!-- <van-cell v-for="item in list" :key="item.id" :title="item.title" :value="item.createTime" @click="goDetail(item.id)"/> -->

      <div class="diyCell" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="top">
          <div class="title van-ellipsis">{{ item.title }}</div>
        </div>
        <div class="time">
          <span>{{ item.createTime }}</span>
          <van-icon name="arrow" />
        </div>
      </div>

    </van-list>
  </div>
</template>
<script>
import { getAnswerList } from '@/api/eventApi'
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
      
    }
  },
  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    goDetail(id) {
      this.$router.push({
        path: '/myanswerDetail?id=' + id
      }) 
    },
    getList() {
      if (this.currentList !== 0) {
        getAnswerList({
          openid: this.$store.getters.openId,
          category: 1,
          type: 3,
          current: this.page,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.list = this.list.concat(res.data.records)
            this.page++ 
          }
        })
      } else {
        this.finished = true; 
      }
      
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.diyCell {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px #efefef;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    .title {
      flex: 1;
      // font-weight: 600;
      font-size: 14px;
    }
  }
  p {
    height: 48px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #5f5f5f;
  }
  .time {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    color: #b9b9b9;
    font-size: 12px;
  }
}
</style>
